@font-face {
    font-family: "nexon-regular";
    src: url("./font/NEXON Lv1 Gothic Low OTF.woff") format("woff");
}

@font-face {
    font-family: "nexon-light";
    src: url("./font/NEXON Lv1 Gothic Low OTF Light.woff") format("woff");
}

@font-face {
    font-family: "nexon-bold";
    src: url("./font/NEXON Lv1 Gothic Low OTF Bold.woff") format("woff");
}

* {
    font-family: "nexon-light";
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
