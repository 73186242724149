* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "nexon-regular";
    src: url("./font/NEXON Lv1 Gothic Low OTF.woff") format("woff");
}

@font-face {
    font-family: "nexon-light";
    src: url("./font/NEXON Lv1 Gothic Low OTF Light.woff") format("woff");
}

@font-face {
    font-family: "nexon-bold";
    src: url("./font/NEXON Lv1 Gothic Low OTF Bold.woff") format("woff");
}

* {
    font-family: "nexon-light";
}

.Start {
    opacity: 0;
}

.End {
    opacity: 1;
    transition: opacity 1s;
}

.HomeStart {
    opacity: 0;
}

.HomeEnd {
    opacity: 1;
    transition: opacity 1.5s;
}
